'use client'

import Link from '@/components/link'
import IconNotFound from '@/public/assets/404.svg'
import useAmplitude from '@/hooks/useAmplitude'
import { useEffect } from 'react'
import { cls } from '@/utils'
import { NO_OUTLINE_STYLE } from '@/constants'

export default function NotFound() {
  const { track } = useAmplitude()

  useEffect(() => {
    track('view:404')
  }, [track])

  return (
    <article className='flex flex-col size-full items-center justify-center'>
      <IconNotFound className='mb-10' />
      <div className='mb-10'>Something went wrong!</div>
      <Link
        className={cls(
          'w-[300px] h-12 rounded-3xl bg-surface-primary text-surface no-underline hover:no-underline active:no-underline hover:opacity-90 active:opacity-90 flex items-center justify-center',
          NO_OUTLINE_STYLE,
        )}
        href='/creations'
      >
        Back to Creations
      </Link>
    </article>
  )
}
